import { render } from 'preact';
import { Button, CheckBox } from '@vgno/core';
import styles from './index.module.scss';
import viewHistory from '../../utils/view-history';
import { useEffect, useState } from 'preact/hooks';
import { setLoginFlowState } from '@vgno/utils';

const VERSION = '06';

const isAfterDark = () => {
    const now = new Date();
    return now.getHours() >= 18 || now.getHours() < 7;
};

const Component = ({ text, login, node, trackEngagement }) => {
    const [isInscreen, setIsInscreen] = useState(false);
    const [isDarkmode, setIsDarkmode] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    trackEngagement({
                        type: 'Engagement',
                        elementAction: 'View',
                        elementName: 'Darkmode Onboarding',
                        elementId: `darkmode-onboarding-${VERSION}`,
                    });
                    setIsInscreen(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 1,
            },
        );

        observer.observe(node);
    }, []);

    useEffect(() => {
        // Reset darkmode on page show
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                setIsDarkmode(false);
                document.documentElement.dataset.colorScheme = 'light';
            }
        });
    }, []);

    return (
        <div
            className={styles.root}
            data-color-scheme={isInscreen ? 'dark' : 'light'}
        >
            <img
                src="https://imbo.vgc.no/s/eLuIywH"
                alt=""
                role="presentation"
                width="343"
                height="211"
            />
            <div className={styles.text}>
                {text}
                <CheckBox
                    className={styles.checkbox}
                    checked={isDarkmode}
                    onPointerUp={() => null}
                    onChange={(event) => {
                        const { checked } = event.target;
                        setIsDarkmode(checked);
                        trackEngagement({
                            type: 'Engagement',
                            elementAction: 'Click',
                            elementName: 'Darkmode Onboarding - Toggle',
                            elementId: `darkmode-onboarding-${VERSION}`,
                        });
                        document.documentElement.dataset.colorScheme = checked
                            ? 'dark'
                            : 'light';
                    }}
                />
                {isDarkmode && (
                    <Button
                        onClick={login}
                        size="large"
                        shape="capsule"
                        resize="fill"
                        style="filled"
                        onPointerUp={() => null}
                    >
                        Lagre valg 🌚
                    </Button>
                )}
            </div>
        </div>
    );
};

const DarkmodeOnboarding = ({ node, trackEngagement }) => {
    const getIdentifier = async () => {
        if (!window.SPiD_Identity) {
            return null;
        }
        try {
            const context = await window.SPiD_Identity.getUserContextData();
            return context?.identifier;
        } catch {
            return null;
        }
    };
    const login = async () => {
        const state = setLoginFlowState({
            medium: 'darkmode-onboarding',
            campaign: 'vgfront',
            content: VERSION,
        });

        document.documentElement.dataset.colorScheme = 'dark';
        const identifier = await getIdentifier();
        window.SPiD_Identity.login({
            state,
            loginHint: identifier,
        });
        trackEngagement({
            type: 'Engagement',
            elementAction: 'Click',
            elementName: 'Darkmode Onboarding - Login',
            elementId: `darkmode-onboarding-${VERSION}`,
        });
    };

    // Vary text after 18:00
    let text = <h2>Slå på mørk modus på VG</h2>;
    if (isAfterDark()) {
        text = (
            <>
                <h2>Litt lyst?</h2>
                <p>
                    <strong>Slå på mørk modus på VG</strong>
                </p>
            </>
        );
    }

    render(
        <Component
            text={text}
            login={login}
            node={node}
            trackEngagement={trackEngagement}
        />,
        node,
    );
};

const isLoggedIn = () =>
    new Promise((resolve) => {
        const _isLoggedIn = () => {
            if (!window.SPiD_Identity) {
                window.addEventListener('identity-initialized', _isLoggedIn);
            } else {
                console.log('Checking login state');
                window.SPiD_Identity.isLoggedIn().then(resolve);
            }
        };
        _isLoggedIn();
    });

export default async (...props) => {
    if (!matchMedia('(prefers-color-scheme: dark)').matches && !isAfterDark()) {
        return null;
    }

    let count = 2;

    if (!matchMedia('(prefers-color-scheme: dark)').matches && isAfterDark()) {
        count = 1;
    }

    const darkmodeHistory = viewHistory(`darkmode-onboarding`);
    darkmodeHistory.clearExpired(72);

    if (darkmodeHistory.get(`darkmode-onboarding-${VERSION}`)?.count >= count) {
        return null;
    }

    if (await isLoggedIn()) {
        return null;
    }

    darkmodeHistory.increase(`darkmode-onboarding-${VERSION}`);

    DarkmodeOnboarding(...props);
};
